<script>
import { camelCase, get } from 'lodash-es';

import {
  BE_ACTIVE_STATUS,
  BE_INACTIVE_STATUS,
  ORG_ACTIVE_STATUS,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_PLAN_COLOR_MAP,
  SUBSCRIPTION_PLAN_ICONS,
  SUBSCRIPTION_PLAN_ICON_COLORS,
  SUBSCRIPTION_SHOW_DAYS_LIMIT,
} from '~/support/constants';
import { subscription } from '~/support/utils';

export default {
  name: 'SubscriptionsWrapper',

  props: {
    subscriptions: {
      required: true,
      type: Object,
    },
  },

  computed: {
    activeSubscriptions() {
      return this.subscriptionsList.filter((subscription) => subscription.status === BE_ACTIVE_STATUS);
    },

    isEdpAndForgeSubscription() {
      return (
        this.subscriptions.EDP.status === ORG_ACTIVE_STATUS && this.subscriptions.FORGE.status === ORG_ACTIVE_STATUS
      );
    },

    isOnlyEdpSubscription() {
      return (
        this.subscriptions.EDP.status === ORG_ACTIVE_STATUS && this.subscriptions.FORGE.status !== ORG_ACTIVE_STATUS
      );
    },

    isOnlyForgeSubscription() {
      return (
        this.subscriptions.EDP.status !== ORG_ACTIVE_STATUS && this.subscriptions.FORGE.status === ORG_ACTIVE_STATUS
      );
    },

    subscriptionsList() {
      return Object.keys(this.subscriptions).map((type) => ({
        ...this.subscriptions[type],
        type,
      }));
    },
  },

  methods: {
    getActiveSubscriptionProps(subscription) {
      return {
        daysLeftText: this.getDaysLeftText(subscription.status, subscription.endDate),
        isPlanExpired: this.isPlanExpired(subscription.status),
        plan: subscription.plan,
        ...this.getStartDateProps(subscription.startDate),
        ...this.getEndDateProps(subscription.endDate),
        ...this.getIconAndPaletteProps(subscription.plan),
      };
    },

    getDaysLeftText(status, endDate) {
      if (this.isPlanExpired(status)) {
        return this.$t('expired');
      }

      if (subscription.daysLeft(endDate) === 0) {
        return this.$t('expiresToday');
      }

      return `${subscription.daysLeft(endDate)} ${this.$t('daysLeft')}`;
    },

    getEndDateProps(endDate) {
      return endDate
        ? {
            expiresOn: `${this.$t('expiresOn')} ${this.$formatting.localizedDate(endDate)}.`,
            expiresOnDate: this.$formatting.localizedDate(endDate),
            hasEndDate: true,
            shouldShowDaysLeft: subscription.daysLeft(endDate) < SUBSCRIPTION_SHOW_DAYS_LIMIT,
          }
        : {
            expiresOn: '',
            expiresOnDate: '',
            hasEndDate: false,
            shouldShowDaysLeft: false,
          };
    },

    getIconAndPaletteProps(plan) {
      return {
        cardPalette: get(SUBSCRIPTION_PLAN_COLOR_MAP, plan, SUBSCRIPTION_PLAN_COLOR_MAP[SUBSCRIPTION_PLANS.free]),
        iconColor: SUBSCRIPTION_PLAN_ICON_COLORS[camelCase(plan)],
        iconName: SUBSCRIPTION_PLAN_ICONS[camelCase(plan)],
      };
    },

    getStartDateProps(startDate) {
      return {
        enrolledOn: `${this.$t('enrolledOn')} ${this.$formatting.localizedDate(startDate)}.`,
        enrolledOnDate: this.$formatting.localizedDate(startDate),
      };
    },

    isPlanExpired(status) {
      return status === BE_INACTIVE_STATUS;
    },
  },

  render() {
    const activeSubscriptions = this.activeSubscriptions.map((subscription) =>
      this.getActiveSubscriptionProps(subscription),
    );

    return this.$slots.default({
      activeSubscriptions,
      isEdpAndForgeSubscription: this.isEdpAndForgeSubscription,
      isOnlyEdpSubscription: this.isOnlyEdpSubscription,
      isOnlyForgeSubscription: this.isOnlyForgeSubscription,
    });
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "daysLeft": "days left",
    "enrolledOn": "Enrolled on:",
    "expired": "Expired",
    "expiresOn": "Expires on:",
    "expiresToday": "Expires Today"
  }
}
</i18n>
